// JavaScript Document

// Scripts written by Jacob Bearce | jacob@bearce.me

jQuery(document).ready(function () {
    "use strict";
	jQuery("select").not("select[multiple], .select select").each(function () {
		if (jQuery(this).css("display") !== "none") {
			jQuery(this).wrap("<div class='select'>");
		}
	});
	/*
	* HTML5 Placeholder Text jQuery Fallback with Modernizr
	* @url     http://uniquemethod.com/
	* @author  Unique Method
	*/
	jQuery(function () {
	    if (!Modernizr.input.placeholder) {
	        jQuery(this).find("[placeholder]").each(function () {
	            if (jQuery(this).val() === "") {
	                jQuery(this).val(jQuery(this).attr("placeholder"));
	            }
	        });
	        jQuery("[placeholder]").focus(function () {
	            if (jQuery(this).val() === jQuery(this).attr("placeholder")) {
	                jQuery(this).val("");
	                jQuery(this).removeClass("placeholder");
	            }
	        }).blur(function () {
	            if (jQuery(this).val() === "" || jQuery(this).val() === jQuery(this).attr("placeholder")) {
	                jQuery(this).val(jQuery(this).attr("placeholder"));
	                jQuery(this).addClass("placeholder");
	            }
	        });
	        jQuery("[placeholder]").closest("form").submit(function () {
	            jQuery(this).find("[placeholder]").each(function () {
	                if (jQuery(this).val() === jQuery(this).attr("placeholder")) {
	                    jQuery(this).val("");
	                }
	            });
	        });
	    }
	});
});
